<template>
  <!-- Sidebar -->
  <div
    name="sidebar"
    class="
      fixed
      flex flex-col
      top-14
      left-0
      w-14
      md:w-56
      bg-side-red
      h-full
      transition-all
      duration-300
      border-r border-gray-50 border-opacity-25
      z-10
    "
  >
    <div class="overflow-y-auto overflow-x-hidden flex flex-col flex-grow">
      <div class="w-full pt-2">
        <router-link to="/">
          <img v-bind:src="setLogo()" class="w-9/12 ml-4 opacity-75" />
        </router-link>
      </div>
      <div class="flex flex-col flex-grow justify-between">
        <ul class="flex flex-col pb-4">
          <li class="px-5 hidden md:block">
            <div class="flex flex-row items-center pt-6 pb-1">
              <div class="list-heading">School</div>
            </div>
          </li>
          <li v-if="showForSchool">
            <router-link
              to="/"
              class="bar-list-item focus:outline-none group"
            >
              <span
                class="bar-list-item-icon opacity-75 group-hover:text-gray-300"
              >
                <i class="fas fa-grip-horizontal" title="Home"></i>
              </span>
              <span class="bar-list-item-title group-hover:text-gray-300"
                >Planning</span
              >
            </router-link>
          </li>

          <li class="px-5 hidden md:block">
            <div class="flex flex-row items-center pt-4 pb-1">
              <div class="list-heading">Analysis</div>
            </div>
          </li>

          <li>
            <router-link
              :to="showForSchool ? '/reports' : '/school-report-list'"
              class="bar-list-item focus:outline-none group"
            >
              <span
                class="bar-list-item-icon opacity-75 group-hover:text-gray-300"
              >
                <i class="fas fa-file-contract" title="Home"></i>
              </span>
              <span class="bar-list-item-title group-hover:text-gray-300"
                >Reporting</span
              >
            </router-link>
          </li>

          <li class="px-5 hidden md:block">
            <div class="flex flex-row items-center pt-4 pb-1">
              <div class="list-heading">Participation</div>
            </div>
          </li>

          <li>
            <router-link
              to="/events"
              class="bar-list-item focus:outline-none group"
            >
              <span
                class="bar-list-item-icon opacity-75 group-hover:text-gray-300"
              >
                <i class="fas fa-calendar-alt" title="Events"></i>
              </span>
              <span class="bar-list-item-title group-hover:text-gray-300"
                >Events</span
              >
            </router-link>
          </li>

          <li>
            <router-link
              to="/activities"
              class="bar-list-item focus:outline-none group"
            >
              <span
                class="bar-list-item-icon opacity-75 group-hover:text-gray-300"
              >
                <i class="fas fa-volleyball-ball" title="Extra Curricular"></i>
              </span>
              <span class="bar-list-item-title group-hover:text-gray-300"
                >Extra Curricular</span
              >
            </router-link>
          </li>

          <li class="px-5 hidden md:block">
            <div class="flex flex-row items-center pt-4 pb-1">
              <div class="list-heading">Resources</div>
            </div>
          </li>

          <li>
            <router-link
              to="/videos"
              class="bar-list-item focus:outline-none group"
            >
              <span
                class="bar-list-item-icon opacity-75 group-hover:text-gray-300"
              >
                <i class="fas fa-file-video"></i>
              </span>
              <span class="bar-list-item-title group-hover:text-gray-300"
                >Video Library
              </span>
            </router-link>
          </li>

          <li>
            <router-link
              to="/resources"
              class="bar-list-item focus:outline-none group"
            >
              <span
                class="bar-list-item-icon opacity-75 group-hover:text-gray-300"
              >
                <i class="fas fa-toolbox" title="Home"></i>
              </span>
              <span class="bar-list-item-title group-hover:text-gray-300"
                >Documents
              </span>
            </router-link>
          </li>


          <li class="px-5 hidden md:block">
            <div class="flex flex-row items-center pt-4 pb-1">
              <div class="list-heading">Manage</div>
            </div>
          </li>
          <li v-if="showForSchool">
            <router-link
              to="/pupils"
              class="group bar-list-item focus:outline-none"
            >
              <span
                class="bar-list-item-icon opacity-75 group-hover:text-gray-300"
              >
                <i class="fas fa-user-friends"></i>
              </span>
              <span class="bar-list-item-title group-hover:text-gray-300"
                >Pupils</span
              >
            </router-link>
          </li>


    
        </ul>
        <ul>
          <li class="px-5 hidden md:block">
            <div class="flex flex-row items-center pt-4">
              <div class="list-heading">Account</div>
            </div>
          </li>
          <li class="hover:opacity-50">
            <router-link to="/account">
              <div class="mb-2 w-11/12 mx-auto flex">
                <div
                  class="
                    w-8
                    h-8
                    rounded-full
                    pt-1
                    text-white text-sm text-center
                    cursor-pointer
                    bg-opacity-75 bg-action-blue
                    m-2
                  "
                >
                  {{ getUserInitials() }}
                </div>
                <div class="w-8/12 mr-2 pt-2 text-sm font-semibold opacity-75">
                  {{ currentUser.schoolName }}
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "sidebar",
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    displaName() {
      return this.currentUser.schoolName;
    },
    showForSchool() {
      return this.currentUser.isSchool == true;
    },
    showForCompany() {
      return this.currentUser.isSchool == false;
    },
  },
  created() {},
  data() {
    return {};
  },
  methods: {
    setLogo() {
      return (
         "/static/images/domains/primary-passport-logo-small.webp"
      );
    },
    getUserInitials() {
      return this.currentUser.schoolName
        .split(" ")
        .map((n) => n[0])
        .join("")
        .substring(0, 3);
    },
  },
};
</script>

<style scoped>
/*****
Side Bar list
******/

.list-heading {
  @apply text-xs font-bold tracking-wide text-gray-300 opacity-50 uppercase;
}
.bar-list-item {
  @apply relative flex flex-row items-center h-12 text-white opacity-75 pr-6 ml-2 mr-4 rounded-lg;
}
.bar-list-item-title {
  @apply ml-2 text-sm font-semibold tracking-wide truncate;
}
.bar-list-item-icon {
  @apply inline-flex justify-center items-center ml-4 text-lg;
}
.bar-list-item.router-link-exact-active,
.bar-list-item.active {
  @apply opacity-100 bg-white bg-opacity-25 text-white;
}
</style>